@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@800&display=swap');

.main_container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;

}

.background_1 {
    position: fixed;
    z-index: -2;
    width: 65vw;
    height: 100vh;
    background-color: #073590;
    overflow: hidden;
}

.background_mobile {
    position: fixed;
    z-index: -2;
    width: 100%;
    height: 100vh;
    background-color: #073590;
    opacity: .6;
    overflow: hidden;
}

.div_lottie {
    position: fixed;
    width: 100vw;
}

.container_left {
    width: 35vw;
    height: 100vh;
    overflow: hidden;
}

.container_left_mobile {
    width: 100vw;
    height: 100vh;
    position: absolute;
}

.container_left_mobile {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -5;
}

.container_right {
    overflow: hidden;
    width: 65vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 5px solid rgb(255, 196, 0);
    /*background-color: #073590dd;*/


}

.container_right_mobile {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*background-color: #073590dd;*/


}

.container_form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.31);
    box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.31);
}

.container_form_mobile {
    display: flex;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    -webkit-box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.31);
    box-shadow: 5px 5px 3px 0px rgba(0, 0, 0, 0.31);
}

.container_logo_main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container_logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container_name {
    color: white;
    display: flex;
}

#logo_azul {
    width: 65px;
    height: 65px;
    /*margin-bottom: 15px;*/
}


#logo_groundlink{
    width: 200px;
    margin-bottom: 25px;
    margin-top: -25px;
}


#azul {
    font-size: 40px;
    font-family: 'Encode Sans Expanded', sans-serif;
}

#handling {
    font-size: 20px;
    font-family: 'Encode Sans Expanded', sans-serif;
}

.container_slogan {
    margin-top: -10px;
    margin-bottom: 20px;
    width: 190px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 196, 0);
    height: 20px;
}

#logo_slogan {
    font-size: 10px;
    color: #073590dd;
    font-weight: 900;
    font-family: 'Encode Sans Expanded', sans-serif;

}

.user_forgot {
    display: flex;
    margin-right: 0;
    margin-bottom: 10px;
}

.header_dialog {
    background-color: #0277BD;
    border-bottom: 3px solid #ffae00;
    color: white;
    text-align: center;

}

.content_dialog {
    text-align: center;
}

#userError {
    color: red;
    font-weight: 700;

}

#passError {
    color: red;
    font-weight: 700;
}

#show_pass {
    background-color: transparent;
    border: none;
    color: gray;
    height: 30px;
    width: 30px;
    margin-left: -5px;
}

#lock_icon {
    height: 30px;
    width: 20px;
    margin-left: -2px;
}

#user_icon {
    background-color: transparent;
    border: none;
    color: gray;
    height: 30px;
    width: 30px;
    margin-left: -5px;
}

#u_icon {
    height: 30px;
    width: 20px;
    margin-left: 3px;
}

@media (prefers-color-scheme: dark) {

    .container_slogan {
        margin-top: -10px;
        margin-bottom: 20px;
        width: 190px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: rgb(255, 196, 0);
        color: white;
        height: 20px;
    }

    .container_name {
        color: white;
        display: flex;
    }
}
.animate span {
    display: inline-block;
    margin-bottom: 15px;
}

.six span {
    color: white;
    opacity: 0;
    transform: translate(-150px, -50px) rotate(-180deg) scale(3);
    animation: revolveScale .4s forwards;
    font-weight: bold;
}

@keyframes revolveScale {
    60% {
        transform: translate(20px, 20px) rotate(30deg) scale(.3);
    }
    100% {
        transform: translate(0) rotate(0) scale(1);
        opacity: 1;
    }
}

.six:hover span {
    animation: revolveScale .4s forwards, neon 2s;
}

@keyframes neon {
    0% {
        color: white;
        text-shadow: 0 0 10px #00fff9, 0 0 20px #00fff9, 0 0 30px #00fff9, 0 0 40px #00fff9, 0 0 70px #00fff9, 0 0 80px #00fff9, 0 0 100px #00fff9, 0 0 150px #00fff9;
    }
    100% {
        color: yellow;
        text-shadow: 0 0 5px #00fff9, 0 0 10px #00fff9, 0 0 15px #00fff9, 0 0 20px #00fff9, 0 0 35px #00fff9, 0 0 40px #00fff9, 0 0 50px #00fff9, 0 0 75px #00fff9;
    }
}

.six:hover{
    cursor: url(https://cur.cursors-4u.net/mechanics/mec-1/mec34.cur), auto !important;

}
.six:hover span {
    cursor: url(https://cur.cursors-4u.net/mechanics/mec-1/mec34.cur), auto !important;
    animation: revolveScale .4s forwards, neon 1s infinite alternate;
}

.animate span:nth-of-type(2) {
    animation-delay: 0.05s;
}
.animate span:nth-of-type(3) {
    animation-delay: 0.1s;
}
.animate span:nth-of-type(4) {
    animation-delay: 0.15s;
}
.animate span:nth-of-type(5) {
    animation-delay: 0.2s;
}
.animate span:nth-of-type(6) {
    animation-delay: 0.25s;
}
.animate span:nth-of-type(7) {
    animation-delay: 0.3s;
}
.animate span:nth-of-type(8) {
    animation-delay: 0.35s;
}
.animate span:nth-of-type(9) {
    animation-delay: 0.4s;
}
.animate span:nth-of-type(10) {
    animation-delay: 0.45s;
}
.animate span:nth-of-type(11) {
    animation-delay: 0.5s;
}
.animate span:nth-of-type(12) {
    animation-delay: 0.55s;
}
.animate span:nth-of-type(13) {
    animation-delay: 0.6s;
}
.animate span:nth-of-type(14) {
    animation-delay: 0.65s;
}
.animate span:nth-of-type(15) {
    animation-delay: 0.7s;
}
.animate span:nth-of-type(16) {
    animation-delay: 0.75s;
}
.animate span:nth-of-type(17) {
    animation-delay: 0.8s;
}
.animate span:nth-of-type(18) {
    animation-delay: 0.85s;
}
.animate span:nth-of-type(19) {
    animation-delay: 0.9s;
}
.animate span:nth-of-type(20) {
    animation-delay: 0.95s;
}
.animate span:nth-of-type(21) {
    animation-delay: 1s;
}
.animate span:nth-of-type(22) {
    animation-delay: 1.05s;
}
.animate span:nth-of-type(23) {
    animation-delay: 1.1s;
}
.animate span:nth-of-type(24) {
    animation-delay: 1.15s;
}
.animate span:nth-of-type(25) {
    animation-delay: 1.2s;
}
.animate span:nth-of-type(26) {
    animation-delay: 1.25s;
}
.animate span:nth-of-type(27) {
    animation-delay: 1.3s;
}
.animate span:nth-of-type(28) {
    animation-delay: 1.35s;
}
.animate span:nth-of-type(29) {
    animation-delay: 1.4s;
}
.animate span:nth-of-type(30) {
    animation-delay: 1.45s;
}